import { Helpers } from '@/helpers';
import { createComponent } from '@/helpers/alpine';
import {
    CustomerLocation,
    TCustomerLocation,
} from '@/services/customer-location';
import { CustomerStore } from '@/services/customer-store';
import { GooglePlaceStore } from '@/services/gplace';

type TStoreItemOps = {
    id_store: string;
    id_storehouse: string;
    store_name: string;
    gplace_id?: string;
    lat: number;
    lng: number;
};

type TRelayItem = {
    address1: string;
    postcode: string;
    city: string;
};

const StoreItem = createComponent((opts: TStoreItemOps) => ({
    id_store: opts.id_store,
    id_storehouse: opts.id_storehouse,
    store_name: opts.store_name,
    gstore: undefined as GooglePlaceStore | undefined,
    lat: opts.lat ?? 0,
    lng: opts.lng ?? 0,
    _distance: -1,
    geocoder: undefined as google.maps.Geocoder | undefined,
    item: undefined as TRelayItem | undefined,

    getDistance(location: TCustomerLocation) {
        const { lat, lng } = this;
        return (
            Helpers.GEO.distanceCosine(
                { lat, lng },
                {
                    lat: location.lat,
                    lng: location.lng,
                },
            ) / 1000
        );
    },

    get isCurrent() {
        return this.id_store == CustomerStore.get()?.id_store;
    },

    get hasGooglePlace() {
        return this.gstore !== undefined;
    },

    get isOpen() {
        return this.gstore?.isOpen;
    },

    get isClose() {
        return !this.gstore?.isOpen;
    },

    get distance() {
        return this._distance != -1 ? Math.round(this._distance) : false;
    },

    get orderStyle() {
        return this._distance != -1 ? Math.floor(this._distance) : 'auto';
    },

    getTimingLabel(...items: string[]) {
        return items.filter(Boolean).join(' ');
    },

    onUpdatedCustomerLocation(location?: TCustomerLocation) {
        this._distance = location ? this.getDistance(location) : -1;
    },

    select() {
        const store_name_short = this.store_name
            .replace('Côté Clôture', '')
            .trim();

        CustomerStore.set({
            id_store: this.id_store,
            id_storehouse: this.id_storehouse,
            id_relay: '',
            store_name: this.store_name,
            store_name_short,
        });
    },

    async init() {
        this.gstore = opts.gplace_id
            ? await GooglePlaceStore.find(opts.gplace_id)
            : undefined;

        if (!this.lat || !this.lng) {
            this.item = opts as unknown as TRelayItem;
            this.geocoder = new google.maps.Geocoder();

            this.geocoder?.geocode(
                {
                    address:
                        this.item.address1 +
                        this.item.postcode +
                        this.item.city,
                },
                (results, status) => {
                    if (status == google.maps.GeocoderStatus.OK && results) {
                        this.lat = results[0].geometry.location.lat();
                        this.lng = results[0].geometry.location.lng();
                    }
                },
            );
        }

        CustomerLocation.onInit((location) => {
            this.onUpdatedCustomerLocation(location);
        });

        CustomerLocation.onUpdate((location) => {
            this.onUpdatedCustomerLocation(location);
        });
    },
}));

export { StoreItem };
